import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import ResponsiveTitle from '../components/ResponsiveTitle'
import Lightbox from '../components/Lightbox'
import BaseButton from '../components/BaseButton'

const PageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
`

// TODO: background scroll should be disabled while active
export const InSituPageTemplate = ({ images, title }) => {
  const { formatMessage } = useIntl()
  const [hasActiveLightbox, setHasActiveLightbox] = useState(true)

  return (
    <Grid fluid style={{ width: '100%' }}>
      <Row>
        <Col xs={12}>
          <ResponsiveTitle title={formatMessage({ id: 'in-situ.title' })} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <PageContent>
              {!hasActiveLightbox && (
                <BaseButton className="button is-large" onClick={() => setHasActiveLightbox(true)}>
                  {formatMessage({ id: 'in-situ.reopen-gallery' })}
                </BaseButton>
              )}
            </PageContent>
          </Row>
        </Col>
      </Row>
      <Row>
        {hasActiveLightbox && (
          <Lightbox
            gatsbyImages={images}
            selectedImageIndex={0}
            onCloseRequest={() => setHasActiveLightbox(false)}
          />
        )}
      </Row>
    </Grid>
  )
}

InSituPageTemplate.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string,
}

const InSituPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <InSituPageTemplate images={frontmatter.images} title={frontmatter.title} />
    </Layout>
  )
}

InSituPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InSituPage

export const pageQuery = graphql`
  query InSituPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "in-situ-page" } }) {
      frontmatter {
        title
        images {
          image {
            childImageSharp {
              # twox: fixed(width: 1416, quality: 80, webpQuality: 90) {
              #   src
              #   srcWebp
              # }
              fixed(width: 1600, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
